<!-- vue coding=utf-8 -->
<!--
 * @Author: Melody
 * @Description: 信用卡申请
 * @Date: 2020-11-17 14:22:01
 * @LastEditors: OBKoro1
 * @LastEditTime: 2020-11-22 21:12:19
 * @FilePath: /yue_quanzhan_h5_new/src/views/localLife/creditCard/creditcardapply.vue
-->
<template>
  <div class="content">
    <div>
      <OpenAppHead :options="options" @getshowimg="getshowimg" />
    </div>
    <van-tabs
      v-model="active"
      swipeable
      :animated="true"
      title-active-color="rgb(219,165,126)"
      background="rgb(247,247,247)"
      line-width="0px"
      line-height="1px"
    >
      <van-tab v-for="(bankInfo,bIndex) in banklist" :key="bIndex" :title="bankInfo.tilte">
        <div class="hotlist">
          <div v-for="(item,index) in bankInfo.data" :key="index" class="hotlistContent">
            <div class="bankContent" @click="Business(item)">
              <div class="bankImg">
                <img :src="item.creditSingleUrl" alt="">
              </div>
            </div>
            <div class="bankContentSpecific">
              <div @click="Business(item)">
                <p class="bankName">{{ item.merCardName }}</p>
                <div v-for="(items,lIndex) in item.labelTitle" :key="lIndex" class="bankDescription">
                  <img
                    src="https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-12-06/18/yuelvhuisAcbKDBpcV1607249442.png"
                    alt=""
                  >
                  <p>{{ items }}</p>
                </div>
              </div>
              <div class="bankBusiness">
                <div class="bankBusinessBtn" @click="Business(item)">{{ item.cardAmount }}</div>
                <div class="bankBusinessBtn" @click="share(item)">{{ item.shareAmount }}</div>
              </div>
              <div style="clear: both" />
            </div>
            <div v-if="index!=2" class="van-hairline--bottom" />
          </div>
        </div>
      </van-tab>
    </van-tabs>
    <div v-if="isShowMoreBank">
      <div class="explain" @click="toMoreBank">
        <div class="explain_le"><span>更多信用卡</span></div>
        <!--        <div class="explain_ri"><span>更多</span><van-icon name="arrow" style="top: 1px;"/></div>-->
      </div>
      <div class="crwdlist">
        <div
          v-for="(item,index) in meltList"
          :key="index"
          class="crwditem"
          :class="item.merCardName.length>5 ? 'crwditem1' : ''"
          @click="goCardApply(item)"
        >
          <img :src="item.merCardImg" alt="">
          <div class="cr_name">{{ item.merCardName }}</div>
        </div>
      </div>
    </div>
    <div class="explain">
      <div class="explain_le"><span>更多贷款</span></div>
    </div>
    <div class="crwdlist">
      <div
        v-for="(item,index) in loanList"
        :key="index"
        style="width: 23.5%"
        class="crwditem"
        :class="item.merCardName.length>5 ? 'crwditem1' : ''"
        @click="goCardApply(item)"
      >
        <img :src="item.merCardImg" alt="" style="border-radius: 50%;">
        <div class="cr_name">{{ item.merCardName }}</div>
      </div>
    </div>
    <div class="fixedbottom">
      <div class="fiexditem1" @click="toApplication">
        申请记录
        <p class="itemtext">点击查看申请记录</p>
      </div>
      <div class="fiexditem2" @click="dd">
        奖励规则
        <p class="itemtext">教你秒懂奖励规则</p>
      </div>
    </div>
    <div v-if="showimg" class="mask" @click="closeshow">
      <div class="picture-box">
        <img class="picture" src="../../../assets/images/wxtwo.jpg">
        <div class="btn">长按图片保存到手机相册</div>
        <!-- <div class="btn" style="background: #ff7800">预览海报长按分享</div> -->
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Icon, Row, Sticky, Tab, Tabs, Toast } from 'vant'

Vue.use(Toast)
  .use(Col)
  .use(Row)
  .use(Sticky)
  .use(Tab)
  .use(Tabs)
  .use(Icon)
import {
  // getBankList,
  getBankListNew
} from '@/services/comeOn'
import OpenAppHead from '../../../component/GoodsDetail/openApp'
import { isWeChat } from '@/utils/common/validate'
import { wxConfigInit } from '@/utils/wx-api'

export default {
  components: {
    OpenAppHead
  },
  data() {
    return {
      bannerImageBgc: '',
      banklist: [],
      bank_banner: '',
      showimg: false,
      hotList: [], // 热门列表
      meltList: '', // 更多信用卡
      loanList: '', // 更多贷款
      topclose: true,
      active: 0,
      isShowMoreBank: false,
      isWxStyle: false
    }
  },
  computed: {
    options() {
      const obj = {
        showtig: true
      }
      const info = {
        product_type: 237
      }
      obj.info = info
      return obj
    }
  },
  created() {
    this.getBankListNew()
    this.configShare()// 分享配置
  },
  mounted() {
    this.isWxStyle = isWeChat()
  },
  methods: {
    // 分享
    configShare() {
      const shareInfo = {
        title: '轻创业零门槛，网申极速来办卡，二十余家银行官方渠道合作，正规大平台，安全有保障！', // 分享标题
        desc: '赚钱请狠戳此链接', // 分享描述
        link: 'https://web.yuetao.vip/Credit/creditcardapply?codeNumber=' + window.localStorage.getItem('recode'), // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: 'https://yuetao-1300766538.cos.ap-beijing.myqcloud.com/yuetao/image/2020-11-26/18/yuelvhuiFDueoQjR7G1606385187.png' // 分享图标
      }
      wxConfigInit(shareInfo)
      // this.$store.dispatch('wxConfigInit', shareInfo)
    },
    // isclose(t){
    //     this.topclose = t
    // },
    closeshow() {
      this.showimg = false
    },
    getshowimg(item) {
      this.showimg = item
    },
    // banner
    // getCashBackBanners() {
    //     getBankListBanner().then(res => {
    //         if(Number(res.code) === 200) {
    //             this.bannerImageBgc = res.data
    //         } else {
    //             Toast(res.msg)
    //         }
    //     })
    // },
    // 奖励说明
    dd() {
      this.$router.push({
        path: '/Rewardrules',
        query: {}
      })
    },
    // 更多银行信用卡
    toMoreBank() {
      this.$router.push({
        path: '/moreBank',
        query: {}
      })
    },
    toApplication() {
      this.$router.push({
        path: '/Credit/ApplicationRecord',
        query: {}
      })
    },
    // 海报
    poster() {
      this.$router.push({
        path: '/infoposter',
        query: {
          mid: window.localStorage.getItem('uid'),
          bankId: 13,
          type: 2
        }
      })
    },
    // 银行列表
    // getBankList() {
    //     getBankList().then(res => {
    //         Toast.loading({
    //             message: '加载中...',
    //             forbidClick: true,
    //         });
    //         if(Number(res.code) === 200) {
    //             this.bank_banner = res.data.bank_banner
    //             this.banklist = res.data.bank_list;//网申列表
    //             this.hotList = res.data.hot_bank_list;//热门列表
    //             this.meltList = res.data.melt_list;//更多信用卡
    //         }
    //     })
    // },
    // 银行列表
    getBankListNew() {
      getBankListNew().then(res => {
        Toast.loading({
          message: '加载中...',
          forbidClick: true
        })
        if (Number(res.code) === 200) {
          this.bank_banner = res.data.bank_banner
          const banklistarr = Array.from(res.data.bank_list)
          this.banklist = banklistarr// 银行列表
          this.loanList = res.data.loan_list// 更多贷款
          if (res.data.melt_list.length === 0) {
            this.isShowMoreBank = false
          } else {
            this.isShowMoreBank = true
            this.meltList = res.data.melt_list// 更多信用卡
          }
        }
      })
    },
    //    网申极速办卡跳转
    goApply(item) {
      this.$router.push({
        path: '/infoCollection',
        query: {
          leadPageUrl: item.leadPageUrl,
          bankCardId: item.merCardId,
          bankId: item.bankId,
          gateWayId: item.gatewayId,
          typeId: item.typeId,
          isPerfect: true
        }
      })
    },
    //    办卡
    Business(item) {
      this.$router.push({
        path: '/infoCollection',
        query: {
          leadPageUrl: item.leadPageUrl,
          bankCardId: item.merCardId,
          bankId: item.bankId,
          gateWayId: item.gatewayId,
          typeId: item.typeId
        }
      })
    },
    // 分享
    share(item) {
      this.$router.push({
        path: '/infoposter',
        query: {
          mid: window.localStorage.getItem('uid'),
          bankId: item.bankId,
          typeId: item.typeId,
          title: item.merCardName,
          merCardId: item.merCardId
        }
      })
    },
    //    更多办卡贷款
    goCardApply(item) {
      this.$router.push({
        path: '/Creditcardinfo',
        query: {
          leadPageUrl: item.leadPageUrl,
          bankCardId: item.merCardId,
          bankId: item.bankId,
          gateWayId: item.gatewayId,
          typeId: item.typeId,
          supportCity: item.supportCity, // 支持城市
          settlementRules: item.settlementRules, // 奖励规则
          cardAmount: item.cardAmount, // 办卡返多少
          shareAmount: item.shareAmount, // 分享返多少
          merCardId: item.merCardId,
          title: item.merCardName
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html, body, .content {
  width: 100%;
  max-width: 375px;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

.content {
  background-color: #f8f8f8;
  min-height: 100vh;

  .ophead {
    height: 40px;
  }

  .ophead.vs {
    transition: height 0.6s ease-in-out;
    height: 0;
    display: none;
  }

  .wxStyle {
    height: 100px;
  }

  /*更多信用卡更多贷款title*/

  .explain {
    display: flex;
    align-items: center;
    padding: 17px;
    color: #3B3453;
    font-family: 'PingFang SC Medium';

    .explain_le {
      flex: 12;
      text-align: left;
      font-size: 14px;
      font-weight: 700;
      align-items: center;
      color: #000;

      img {
        width: 21px;
        height: 21px;
        margin-right: 5px;
      }
    }

    .explain_ri {
      margin-left: auto;
      font-size: 13px;
      line-height: 25px;
      font-weight: 500;
    }
  }

  /*更多列表*/

  .crwdlist {
    width: 100%;
    height: 97px;
    padding: 12px;
    background-color: #fff;
    font-family: 'PingFang SC Regular';
    display: flex;

    .crwditem {
      width: 25%;
      background-color: #fff;
      text-align: center;

      img {
        width: 41px;
        height: 41px;
        vertical-align: top;
      }

      .cr_name {
        font-size: 13px;
        color: #333;
        font-weight: 500;
        margin-top: 10px;
        text-align: center;
      }
    }

    .crwditem1 {
      width: 30%;
    }
  }

  /*推荐列表*/

  .hotlist {
    padding: 22px 0 0 15px;
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    background-color: #fff;

    .hotlistContent {
      width: 100%;
      margin-bottom: 17px;
      display: flex;

      .bankContent {
        display: flex;

        .bankImg {
          width: 107px;
          margin-right: 13px;

          img {
            width: 100%;
            height: 67px;
          }
        }
      }

      .bankContentSpecific {
        text-align: left;

        .bankName {
          color: #242424;
          font-size: 17px;
          font-weight: 700;
        }

        .bankDescription {
          display: flex;
          margin-top: 13px;

          img {
            width: 12px;
            height: 12px;
          }

          p:nth-child(2) {
            color: #666;
            font-size: 13px;
            margin-left: 5px;
          }
        }

        .bankBusiness {
          float: left;
          margin-bottom: 17px;

          .bankBusinessBtn {
            float: left;
            height: 25px;
            padding-left: 10px;
            padding-right: 10px;
            line-height: 27px;
            text-align: center;
            color: #fff;
            font-size: 13px;
            background-color: #DCA57B;
            border-radius: 13px;
            margin-top: 13px;
          }

          div:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
  }

  /*关注公众号蒙版*/

  .mask {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 99;

    .picture-box {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .picture {
        display: block;
        width: 280px;
        // height: 360px;
        background: rebeccapurple;
        margin: 0 auto;
      }

      .btn {
        width: 290px;
        height: 44px;
        border-radius: 22px;
        background: #ed2e2e;
        text-align: center;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        margin-top: 15px;
      }
    }

    .sku {
      width: 100%;
      min-height: 500px;
      border-radius: 8px 8px 0 0;
      background: #fff;
      position: absolute;
      left: 0;
      bottom: 0;
      box-sizing: border-box;
      padding: 14px;

      .tp {
        display: flex;
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        .goods-cover {
          width: 78px;
          height: 78px;
          border-radius: 4px;
          background: #e0e0e0;
        }

        .goods-price {
          width: 250px;
          height: 78px;
          display: flex;
          // align-items: center;
          flex-direction: column;
          justify-content: space-around;
          box-sizing: border-box;
          padding-left: 12px;

          .pris {
            text-align: left;

            .hjy {
              color: #ed2e2e;
              font-size: 13px;
            }

            .hjy-count {
              color: #ed2e2e;
              font-size: 18px;
              font-weight: bold;
              margin-right: 12px;
            }

            .spe-icon {
              font-size: 14px;
              color: #ed2e2e;
              font-weight: bold;
              margin-left: 4px;
            }

            .gwj {
              color: #141f33;
              font-size: 13px;
            }
          }

          .guige {
            text-align: left;
            font-size: 13px;
            color: #99a0ad;
          }

          .close {
            width: 16px;
            height: 16px;
            background: url("../../../assets/images/closesku.png") no-repeat;
            background-size: 100% 100%;
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
      }

      .out {
        height: 200px;
        overflow: hidden;
        margin-bottom: 50px;

        .inner {
          height: 200px;
          overflow: auto;
        }
      }

      .status-box {
        width: 100%;
        margin-bottom: 8px;

        .tits {
          font-size: 16px;
          color: #141f33;
          margin-bottom: 12px;
          text-align: left;
        }

        .status {
          width: 100%;
          overflow: hidden;

          .box {
            width: auto;
            padding: 6px 20px;
            background: #f0f2f5;
            border-radius: 4px;
            font-size: 14px;
            color: #141414;
            display: inline-block;
            margin-right: 12px;
            margin-bottom: 12px;
            float: left;
          }

          .act {
            background: #fae9e8;
            color: #ed2e2e;
          }
        }
      }
    }

    .num-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 16px;
      color: #141f33;
      margin-bottom: 23px;

      .handles {
        .edge,
        .add {
          display: inline-block;
          width: 28px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
        }

        .cou {
          display: inline-block;
          width: 45px;
          height: 28px;
          background: #f0f2f5;
          border-radius: 4px;
          color: #616b80;
          font-size: 12px;
          line-height: 28px;
          margin: 0 4px;
        }
      }
    }

    .code-txt {
      width: 100%;
      text-align: center;
      font-size: 12px;
      color: #99a0ad;
      margin-bottom: 4px;
    }

    .btn-box {
      width: 100%;
      height: 44px;
      border-radius: 22px;
      overflow: hidden;
      display: flex;
      justify-content: space-between;

      .btn1 {
        width: 50%;
        height: 100%;
        line-height: 44px;
        background: #201e1d;
        color: #fff;
        font-size: 16px;
      }

      .btn2 {
        background: #ff001c;
      }

      .btn3 {
        width: 100%;
        height: 100%;
        line-height: 44px;
        color: #fff;
        font-size: 16px;
        background: #ff001c;
      }
    }
  }

  /*底部申请奖励tab*/

  .fixedbottom {
    padding: 15px 9px 35px 9px;
    display: flex;
    background-color: rgb(248, 248, 248);
    overflow: hidden;

    .fiexditem1, .fiexditem2 {
      width: 50vw;
      height: 10vh;
      font-size: 18px;
      color: #000;
      background-size: 100% 100%;
      line-height: 7vh;
      text-align: left;
      padding-left: 5vw;

      p {
        font-size: 12px;
        color: #999;
        line-height: 0vh;
      }
    }

    .fiexditem1 {
      background-image: url("../../../assets/images/btn-left.png");
    }

    .fiexditem2 {
      background-image: url("../../../assets/images/btn-right.png");
    }
  }
}

/deep/ .van-tabs__nav .van-tabs__nav--line {
  background-color: #f8f8f8;
}

/deep/ .van-icon {
  margin-top: 1px;
}
</style>
